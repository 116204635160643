import {Box, Button, Grid, Typography, Link as MuiLink} from "@material-ui/core";
import Link from "next/link";
import Routes from "../routes";
import React from "react";
import {useRouter} from "next/router";
import GoogleSignInButton from "./auth/googleSignInButton";
import AppleSignInButton from "./auth/appleSignInButton";
import HelpButton from "./buttons/helpButton";
import PasskeySignInButton from "./auth/passkey-signin-button";
import {createStyles, makeStyles} from "@material-ui/styles";
import {Theme} from "@material-ui/core/styles";

export default function SignInOrCreateAccount() {
    const router = useRouter();
    const classes = styles();
    return (
        <div>
            <Typography variant={"h3"}>
                Existing Customer?
            </Typography>
            <Box mt={0.5} mb={2}>
                <Typography variant={"body2"} color={"textSecondary"}>
                    Please sign in if you already have an Offshoot account.
                </Typography>
            </Box>
            <Grid container={true} alignItems={"center"} spacing={2}>
                <Grid item={true}>
                    <PasskeySignInButton />
                </Grid>
                <Grid item={true}>
                    <GoogleSignInButton
                        state={{
                            redirectTo: router.asPath
                        }} />
                </Grid>
                <Grid item={true}>
                    <AppleSignInButton
                        state={{
                            redirectTo: router.asPath
                        }} />
                </Grid>
            </Grid>
            <Box mt={2}>
                <Link
                    prefetch={false}
                    passHref={true}
                    href={{
                        pathname: Routes.Accounts.SignIn,
                        query: {post: router.asPath}
                    }}>
                    <MuiLink className={classes.anotherWay}>
                        Sign In A Different Way
                    </MuiLink>
                </Link>
            </Box>
            {router.asPath !== Routes.Accounts.Create && (
                <div>
                    <Box mt={3}>
                        <Typography variant={"h3"}>
                            Not Yet A Customer?
                        </Typography>
                    </Box>
                    <Box mt={0.5} mb={2}>
                        <Typography variant={"body2"} color={"textSecondary"}>
                            To get quotes, book gear and see live
                            product availability etc, we need you
                            to create an account. It won&lsquo;t take long!
                        </Typography>
                    </Box>
                    <Link
                        prefetch={false}
                        passHref={true}
                        href={{
                            pathname: Routes.Accounts.Create,
                            query: {cr: router.asPath}
                        }}>
                        <Button variant={"outlined"} color={"secondary"}>
                            Create An Account
                        </Button>
                    </Link>
                </div>
            )}
            {router.asPath !== Routes.Help.Learn && (
                <div>
                    <Box mt={3}>
                        <Typography variant={"h3"}>
                            Help
                        </Typography>
                    </Box>
                    <Box mt={0.5} mb={2}>
                        <Typography variant={"body2"} color={"textSecondary"}>
                            Learn how to use the Offshoot website to create quotes and
                            book equipment 24/7.
                        </Typography>
                    </Box>
                    <HelpButton text={"Learn How"} />
                </div>
            )}
        </div>
    )
}

const styles = makeStyles((theme: Theme) => createStyles({
    anotherWay: {
        textDecoration: "underline",
        textUnderlineOffset: "5px",
        textDecorationStyle: "dotted",
        border: "none",
        textTransform: "uppercase",
        color: theme.palette.text.secondary,
        fontSize: "0.85rem"
    }
}));