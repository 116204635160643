import React, {useCallback} from "react";
import {Box, Button, Modal, Snackbar, Theme, Typography} from "@material-ui/core";
import clsx from "clsx";
import {AnimatePresence, motion} from "framer-motion";
import Logger from "../logger";
import {createStyles, makeStyles} from "@material-ui/styles";

type StatusType = "info"|"error"|"critical";
type CloseHandler = () => void;

export interface IStatusContext {
    setStatus: (
        message: string|undefined,
        type?: StatusType,
        title?: string,
        onClose?: CloseHandler
    ) => void;
}

export const StatusContext = React.createContext<IStatusContext>({
    setStatus: () => {}
});

const StatusContextProvider: React.FC = (props) => {
    const classes = styles();
    const [statusText, setStatusText] = React.useState<string|undefined>(undefined);
    const [statusType, setStatusType] = React.useState<StatusType|undefined>(undefined);
    const [statusTitle, setStatusTitle] = React.useState<string|undefined>(undefined);
    const [closeHandler, setCloseHandler] = React.useState<{handler: CloseHandler}|undefined>(undefined);

    const setStatus: IStatusContext["setStatus"] = useCallback((
        message,
        type?,
        title?,
        onClose?
    ) => {
        Logger.info(`Setting status to: ${message}`);
        setStatusText(message);
        setStatusType(type);
        setStatusTitle(title);
        setCloseHandler({
            handler: onClose
        })
    }, []);

    const clearStatus = useCallback(() => {
        Logger.info('Clearing status.');
        setStatusText(undefined);
        setStatusTitle(undefined);
        setCloseHandler(undefined);
    }, []);

    const handleClose = useCallback(() => {
        if(closeHandler) {
            closeHandler.handler?.();
        }
        clearStatus();
    }, [closeHandler, clearStatus]);

    const context = React.useRef<IStatusContext>({setStatus}).current;

    return (
        <StatusContext.Provider value={context}>
            {props.children}
            {!!statusText && statusType !== "critical" && (
                <Snackbar
                    anchorOrigin={{horizontal: "center", vertical: "top"}}
                    open={!!statusText}
                    autoHideDuration={7000}
                    onClose={clearStatus}>
                    <div className={clsx(classes.snackBar, {[classes.error]: statusType === "error"})}>{statusText}</div>
                </Snackbar>
            )}
            <AnimatePresence>
                {!!statusText && statusType === "critical" && (
                    <Modal
                        open={true}>
                        <section className={classes.container}>
                            <motion.div
                                variants={{
                                    initial: {
                                        scale: 0,
                                        opacity: 0.3,
                                    },
                                    normal: {
                                        scale: 1,
                                        opacity: 1
                                    }
                                }}
                                initial={"initial"}
                                animate={"normal"}
                                exit={{scale: 0, opacity: 0.3}}
                                className={classes.critical}>
                                <Typography variant={"h2"} display={"block"} align={"center"}>
                                    {statusTitle ?? "That Didn't Work"}
                                </Typography>
                                <Box mt={1}>
                                    <Typography className={classes.message}>
                                        {statusText}
                                    </Typography>
                                </Box>
                                <Box mt={2.5}>
                                    <Button
                                        variant={"outlined"}
                                        size={"large"}
                                        onClick={handleClose}>
                                        OK
                                    </Button>
                                </Box>
                            </motion.div>
                        </section>
                    </Modal>
                )}
            </AnimatePresence>
        </StatusContext.Provider>
    )
};

export default StatusContextProvider;

const styles = makeStyles((theme: Theme) => createStyles({
    snackBar: {
        backgroundColor: "black",
        color: "white",
        fontSize: theme.typography.body2.fontSize,
        fontWeight: theme.typography.fontWeightMedium,
        padding: theme.spacing(1, 2)
    },
    error: {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.getContrastText(theme.palette.error.main)
    },
    container: {
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        flexFlow: "column",
        placeContent: "center",
        backdropFilter: "blur(10px)"
    },
    critical: {
        width: "min(75vw, 75vh)",
        height: "min(75vw, 75vh)",
        borderRadius: "50%",
        alignSelf: "center",
        padding: theme.spacing(6),
        display: "flex",
        flexFlow: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.getContrastText(theme.palette.secondary.main),
        "& .MuiTypography-root": {
            textAlign: "center"
        },
        "& .MuiButton-root": {
            color: theme.palette.getContrastText(theme.palette.secondary.main),
            "&.MuiButton-outlined": {
                borderColor: theme.palette.getContrastText(theme.palette.secondary.main),
            },
        },
        [theme.breakpoints.up("md")]: {
            padding: theme.spacing(12),
        }
    },
    message: {
        fontSize: theme.typography.body1.fontSize,
        maxWidth: "500px",
        [theme.breakpoints.up("md")]: {
            fontSize: theme.typography.h2.fontSize
        }
    }
}));
