import {IArticle, IProduct} from "../types";
import Config from "../config";

/***
 * Takes a JS object, stringifies it, base64 encodes it
 * and then URL encodes it. Useful for passing state as a URL
 * parameter (e.g. in OAuth callbacks).
 *
 * @param obj
 */
export function encodeBase64(obj: any): string {
    const jsonString = JSON.stringify(obj);
    return encodeURIComponent(btoa(jsonString));
}

/**
 * Take a URI encoded base64 string, decodes it, converts
 * from base64 to ascii and then JSON parses it.
 *
 * @param data
 */
export function decodeBase64<T>(data: string): T {
    const jsonString = decodeURIComponent(atob(data));
    return JSON.parse(jsonString) as T;
}

/**
 * Returns an absolute URL for the given path, or the root path if omitted.
 */
export function absoluteBaseUrl(path="/") {
    return `${Config.DeploymentUrl}${path}`;
}

/**
 * Generates an absolute URL at which a product can be viewed.
 *
 * @param product
 */
export function absoluteProductUrl(product: IProduct) {
    if(!product?.slug) {
        throw new Error("Cannot generate product URL when the product is null or has no slug.");
    }
    return `${Config.DeploymentUrl}/product/${product.slug}`
}

/**
 * Generates an absolute URL at which an article can be viewed.
 *
 * @param article
 */
export function absoluteArticleUrl(article: IArticle) {
    if(!article?.slug) {
        throw new Error("Cannot generate article URL when the article is null or has no slug.");
    }
    return `${Config.DeploymentUrl}/articles/${article.slug}`
}