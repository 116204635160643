import {ButtonProps, CircularProgress} from "@material-ui/core";
import StandardButton from "../buttons/standardButton";
import GoogleLogo from "../icons/google/google";
import {useCallback, useState} from "react";
import {getProviderRedirectUri} from "../../utils/oauth";
import AppConfig from "../../config";
import {IOauthCallbackState} from "../../types";
import {encodeBase64} from "../../utils/url";
import {IdentityProvider} from "../../__generated__/globalTypes";

interface IGoogleSignInButtonProps extends Pick<ButtonProps, "size"|"disabled"> {
    label?: string;
    onClick?: () => void;
    state?: Omit<IOauthCallbackState,"provider">;
}

export default function GoogleSignInButton({label, onClick, state, disabled, ...props}: IGoogleSignInButtonProps) {
    const [redirecting, setRedirecting] = useState<boolean>(false);

    const handleClick = useCallback(() => {
        setRedirecting(true);
        onClick?.();
        const redirectUri = encodeURIComponent(getProviderRedirectUri(IdentityProvider.google));
        const clientId = encodeURIComponent(AppConfig.GoogleOAuthClientId);
        //
        // The state will be passed back to us in the callback. We will pass it as a
        // URL encoded base64 string and decode it in the callback.
        //
        const b64State = encodeBase64({
            ...state,
            provider: IdentityProvider.google
        } as IOauthCallbackState);
        window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?scope=openid%20profile%20email&include_granted_scopes=true&response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&state=${b64State}`;
    }, [state, onClick]);

    return (
        <StandardButton
            {...props}
            disabled={disabled || redirecting}
            startIcon={redirecting ? <CircularProgress size={15} /> : <GoogleLogo useGoogleColours={true} />}
            onClick={handleClick}>
            {label ?? 'Sign In With Google'}
        </StandardButton>
    )
}