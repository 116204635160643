import React, {useCallback, useMemo} from "react";
import {useContext} from "react";
import {AuthContext} from "./authContext";
import {ICustomerAccount, IJob, IJobLine, IJobPricing, IProduct, IShortfall, Period} from "../types";
import dynamic from "next/dynamic";
import {useRouter} from "next/router";

export type ProductAndQuantityArray = Array<{id: string, quantity: number}>;

export interface IQuoteContext {
    period: Period;
    setPeriod: (period: Period) => void;
    addProductToQuote: (product: IProduct, quantity?: number, source?: HTMLElement) => void;
    removeProductsFromQuote: (products: IProduct[]) => void;
    removeUnavailableProducts: () => Promise<void>;
    isProductInQuote: (productId: string) => boolean;
    /**
     * In situations where a customer wants to get a new quote based on an earlier one,
     * or add products in a favourites list to a quote, we need to fetch current pricing
     * info etc from the API. This will also allow us to filter out any products that
     * are no longer available.
     */
    fetchAndAddProductsToQuote: (products: ProductAndQuantityArray, showProducts, clearExisting) => Promise<void>;
    lines: IJobLine[];
    shortfall: IShortfall[];
    isShortfall: boolean;
    setLineOrder: (productOrder: IJobLine[]) => void;
    totalQuantity: number;
    showProducts: () => void;
    hideProducts: () => void;
    creatingJob: boolean;
    pricing?: IJobPricing;
    jobReference?: string;
    jobId?: string;
    customerReference?: string;
    setCustomerReference: (reference: string) => void;
    setCustomerAccount: (account: ICustomerAccount) => void;
    customerAccount: ICustomerAccount|null;
    /**
     * Applies or removes the damage waiver from the job. If there
     * is no active job, this has no effect.
     *
     * @param apply
     */
    applyDamageWaiver: (apply: boolean) => void;
    /**
     * Starts a new quote.
     */
    startOver: () => void;
    /**
     * Accepts the active quote.
     */
    acceptQuote: () => Promise<void>;
    /**
     * Whether the current job quote can be accepted or not.
     */
    canAcceptStatus: {
        canAccept: boolean;
        reason?: string;
    };
    applyPromo: (promoCode: string) => Promise<void>;
    /**
     * The job behind the quote, if there is one.
     */
    activeJob?: IJob;
}

export const defaultQuoteContext: IQuoteContext = {
    period: [null, null],
    setPeriod: () => null,
    addProductToQuote: () => null,
    removeProductsFromQuote: () => null,
    removeUnavailableProducts: async () => null,
    isProductInQuote: () => false,
    fetchAndAddProductsToQuote: () => null,
    lines: [],
    shortfall: [],
    isShortfall: false,
    setLineOrder: () => null,
    totalQuantity: 0,
    showProducts: () => null,
    hideProducts: () => null,
    creatingJob: false,
    applyDamageWaiver: () => null,
    setCustomerReference: () => null,
    setCustomerAccount: () => null,
    customerAccount: null,
    startOver: () => null,
    acceptQuote: () => null,
    applyPromo: () => null,
    canAcceptStatus: {canAccept: false}
}

export const QuoteContext = React.createContext<IQuoteContext>(defaultQuoteContext);

const FullContext = dynamic(
    () => import("./quoteContextFull")
)

const QuoteContextWrapper: React.FC = ({children}) => {
    const {user, showSignInDialog} = useContext(AuthContext);
    const router = useRouter();
    const inCallback =  /\/oauth-callback/.test(router.asPath)

    const handleAddProductToQuote: IQuoteContext["addProductToQuote"] = useCallback(() => {
        showSignInDialog(true);
    }, [showSignInDialog]);

    const context: IQuoteContext = useMemo(() => {
        return {
            ...defaultQuoteContext,
            addProductToQuote: handleAddProductToQuote
        }
    }, [handleAddProductToQuote]);

    if(!!user && !inCallback) {
        return (
            <FullContext>
                {children}
            </FullContext>
        )
    }

    return (
        <QuoteContext.Provider value={context}>
            {children}
        </QuoteContext.Provider>
    )
}

export default QuoteContextWrapper;