
const Routes = {
    /**
     * Where to send the user if no redirect has been specified after
     * they authenticate or create an account.
     */
    Default: '/',
    Accounts: {
        SignIn: '/accounts/sign-in',
        Create: '/accounts/create',
        RequiredDocuments: '/accounts/add-documents',
        Pro: '/accounts/pro',
        Details: '/accounts/details',
        RentalHistory: '/accounts/history',
        JobDetails: '/accounts/history/job/[reference]',
        PaymentMethods: '/accounts/payment-methods',
        OAuthCallback: '/accounts/oauth-callback'
    },
    Profile: {
        Home: `/profile`,
        Security: '/profile/security',
        Favourites: '/profile/favourites'
    },
    Products: {
        AllCategories: '/products',
        Category: '/hire/[slug]',
        Details: '/product/[slug]'
    },
    Articles: {
        Home: '/articles',
        Article: '/articles/[slug]',
        Tag: '/articles/tag/[slug]'
    },
    Help: {
        Learn: '/articles/how-to-use-the-offshoot-website',
        TaggedHelp: '/articles/tag/help',
        GetQuote: '/articles/getting-an-instant-quote',
        CreatingAccount: '/articles/creating-an-account'
    }
}

export default Routes;
