import React, {useMemo, useEffect, useState} from 'react';
import {ThemeProvider} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import createTheme from '../theme';
import {AppProps} from "next/app";
import Script from "next/script";
import Head from "next/head";
import {useApollo} from "../apolloClient";
import {ApolloProvider, useReactiveVar} from "@apollo/client";
import {SocketIOProvider} from "use-socketio";
import AppConfig from "../config";
import StatusContextProvider from "../context/statusContext";
import {useMediaQuery} from "@material-ui/core";
import AuthContextProvider from "../context/authContext";
import {useRouter} from "next/router";
import Logger from "../logger";
import Routes from "../routes";
import {motion, AnimatePresence} from "framer-motion";
import QuoteContextWrapper from "../context/quoteContext";
import {StyledEngineProvider} from '@material-ui/core/styles';
import {StorageKeys} from "../constants";
import {colourModePreference} from "../cache";
import FavouritesContextProvider from "../context/favouritesContext";
import * as Sentry from "@sentry/nextjs";
import SearchContextProvider from "../context/searchContext";
import MenuContextProvider from "../context/menuContext";
import ActionContextProvider from "../context/actionContext";
import dynamic from "next/dynamic";

const PRODUCT_DETAILS_REG = new RegExp(Routes.Products.Details.replace("[slug]", ""));
const CATEGORY_REG = new RegExp(Routes.Products.Category.replace("[slug]", ""));

interface IPageProps {
}

/**
 * Loaders to show if we are waiting for a static page to render.
 */
const DefaultLoader = dynamic(() => import("../components/skeletons/defaultLoader"));
const ProductLoader = dynamic(() => import("../components/skeletons/productDetailSkeleton"));
const CategoryLoader = dynamic(() => import("../components/skeletons/categorySkeleton"));

const CustomErrorPage = dynamic(() => import("../pages/_error"));

export default function CustomerApp(props: AppProps<IPageProps>) {
    const router = useRouter();
    const {Component, pageProps} = props;
    const apolloClient = useApollo(pageProps.initialApolloState);
    const colourMode = useReactiveVar(colourModePreference);
    const prefersDarkScheme = useMediaQuery("(prefers-color-scheme: dark)");
    const [Loader, setLoader] = useState<React.ComponentType | null>(null);

    const theme = useMemo(() => {
        Logger.debug(`Dark mode preference from application state is: ${colourMode}`);
        let darkMode = prefersDarkScheme;
        if (!!colourMode?.trim()) {
            darkMode = colourMode === "dark";
        }
        return createTheme(darkMode);
    }, [prefersDarkScheme, colourMode]);

    /**
     * For now, we are not using service workers. The previous app did, so unregister
     * any service workers for the domain we are served at.
     */
    useEffect(() => {
        if ("serviceWorker" in navigator) {
            navigator.serviceWorker.getRegistrations().then(function (registrations) {
                for (let registration of registrations) {
                    registration.unregister().then(() => {
                        window.location.reload();
                    });
                }
            });
        }
    }, []);

    useEffect(() => {
        const modePreference = window.localStorage.getItem(StorageKeys.ColourMode);
        Logger.debug(`Colour mode preference in local storage is: ${modePreference}`);
        if (modePreference !== null) {
            Logger.debug(`Setting colour mode preference based on stored value of ${modePreference}`);
            colourModePreference(modePreference);
        } else {
            colourModePreference(prefersDarkScheme ? "dark" : "light");
        }
    }, [prefersDarkScheme]);

    useEffect(() => {
        let timeoutHandle: NodeJS.Timeout | null = null;
        const handleRouteStart = (url: string) => {
            Logger.debug(`Navigating to ${url}`);
            let skeleton;
            if (PRODUCT_DETAILS_REG.test(url)) {
                skeleton = ProductLoader;
            } else if (CATEGORY_REG.test(url)) {
                skeleton = CategoryLoader;
            } else {
                skeleton = DefaultLoader;
            }
            timeoutHandle = setTimeout(() => {
                if (timeoutHandle) {
                    setLoader(skeleton);
                    window.scrollTo(0, 0);
                }
            }, 100);
        }

        const handleRouteFinish = () => {
            if (timeoutHandle) {
                clearTimeout(timeoutHandle);
            }
            Logger.debug("In handleRouteFinish");
            setLoader(null);
        }

        const handleRouteError = (_err: any) => {
            Logger.debug("In handleRouteError");
            if (timeoutHandle) {
                clearTimeout(timeoutHandle);
            }
            setLoader(null);
        }

        router.events.on("routeChangeStart", handleRouteStart);
        router.events.on("routeChangeComplete", handleRouteFinish);
        router.events.on("routeChangeError", handleRouteError);

        return () => {
            if (timeoutHandle) {
                clearTimeout(timeoutHandle);
            }
            router.events.off("routeChangeStart", handleRouteStart);
            router.events.off("routeChangeComplete", handleRouteFinish);
            router.events.off("routeChangeError", handleRouteFinish);
        }
    }, [router]);

    useEffect(() => {
        // Remove the server-side injected CSS.
        const jssStyles = document.querySelector('#jss-server-side');
        if (jssStyles) {
            jssStyles?.parentElement?.removeChild(jssStyles);
        }
    }, []);

    return (
        <ApolloProvider client={apolloClient}>
            <Head>
                <meta name="theme-color" content={theme.palette.background.default}/>
            </Head>
            <Script
                strategy={"afterInteractive"}
                src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GA_ID}`}/>
            <Script id={"google-analytics"}>
                {
                    `
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', '${process.env.NEXT_PUBLIC_GA_ID}');
                    `
                }
            </Script>
            <StyledEngineProvider injectFirst={true}>
                <ThemeProvider theme={theme}>
                    <CssBaseline/>
                    <Sentry.ErrorBoundary fallback={() => <CustomErrorPage/>}>
                        <SocketIOProvider url={AppConfig.EventBrokerHost}>
                            <StatusContextProvider>
                                <AuthContextProvider>
                                    <ActionContextProvider>
                                        <MenuContextProvider>
                                            <SearchContextProvider>
                                                <FavouritesContextProvider>
                                                    <QuoteContextWrapper>
                                                        <AnimatePresence exitBeforeEnter={true}>
                                                            {!Loader && (
                                                                <motion.div
                                                                    initial={{opacity: 0}}
                                                                    animate={{opacity: 1}}
                                                                    exit={{opacity: 0}}>
                                                                    <Component {...pageProps} />
                                                                </motion.div>
                                                            )}
                                                            {!!Loader && <Loader/>}
                                                        </AnimatePresence>
                                                    </QuoteContextWrapper>
                                                </FavouritesContextProvider>
                                            </SearchContextProvider>
                                        </MenuContextProvider>
                                    </ActionContextProvider>
                                </AuthContextProvider>
                            </StatusContextProvider>
                        </SocketIOProvider>
                    </Sentry.ErrorBoundary>
                </ThemeProvider>
            </StyledEngineProvider>
        </ApolloProvider>
    );
}
