import React, {useCallback, useContext, useMemo, useState} from "react";
import {AuthContext} from "./authContext";
import {IFavourite, IFavouriteList, IProduct} from "../types";
import FavouritesPopup from "../components/dialogs/favouritesPopup";
import {useRouter} from "next/router";
import dynamic from "next/dynamic";

export type ProductIdAndName = Pick<IProduct, "id"|"name">;

export interface IFavouritesContext {
    isFavourite: (productId: string) => boolean;
    lists: IFavouriteList[];
    createList: (name: string, productIds?: string[]) => Promise<void>;
    deleteList: (listId: string) => Promise<void>;
    changeListName: (listId: string, newName: string) => Promise<void>;
    setOrder: (newOrder: IFavouriteList[]) => Promise<void>;
    setProductOrder: (listId: string, newOrder: IFavourite[]) => Promise<void>;
    showFavouritesPopup: (anchor: HTMLElement, product: ProductIdAndName) => void;
    toggleIsFavourite: (productId: string, listId: string) => Promise<boolean>;
}

const defaultContext: IFavouritesContext = {
    isFavourite: () => false,
    lists: [],
    createList: () => null,
    deleteList: () => null,
    changeListName: () => null,
    setOrder: () => null,
    setProductOrder: () => null,
    showFavouritesPopup: () => null,
    toggleIsFavourite: () => null
}

export const FavouritesContext = React.createContext<IFavouritesContext>(defaultContext);

const FullContext = dynamic(
    () => import("./favouritesContextFull")
)

const FavouritesContextProvider: React.FC = ({children}) => {
    const {user} = useContext(AuthContext);
    const router = useRouter();
    const [popAnchor, setPopAnchor] = useState<HTMLElement|null>(null);
    const inCallback =  /\/oauth-callback/.test(router.asPath);

    const handleShowPopup = useCallback((anchor: HTMLElement) => {
        setPopAnchor(anchor);
    }, []);

    const context: IFavouritesContext = useMemo(() => {
        return {
            ...defaultContext,
            showFavouritesPopup: handleShowPopup
        }
    }, [handleShowPopup]);

    if(!!user && !inCallback) {
        return (
            <FullContext>
                {children}
            </FullContext>
        )
    }

    return (
        <FavouritesContext.Provider value={context}>
            {children}
            <FavouritesPopup
                anchor={popAnchor}
                product={null}
                onClose={() => setPopAnchor(null)} />
        </FavouritesContext.Provider>
    )
};

export default FavouritesContextProvider;