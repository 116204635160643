import {IProduct} from "./types";

export interface IEventBase {
    type: any;
}

/**
 * We can emit events via Socket.IO to be propagated to google
 * pub/sub using the following shape. The Event Broker will
 * send any event on when `propagateToPubSub` is true.
 */
export interface IClientEvent<T extends IEventBase> {
    topic: string;
    propagateToPubSub: boolean;
    clientId: string;
    data: T;
}

export enum UserEventType {
    DetailsUpdated="details.updated",
    RequestedDocumentAdded="requested_document.added",
    RequestedDocumentStatusUpdated="requested_document.status_updated",
    ViewedFullscreenProductImage="user_action.viewed_fullscreen_product_images",
    ViewedProductPageTab="user_action.viewed_product_page_tab",
    CustomerAppAction="user_action.customer_app",
    ProductSearch="user_action.product_search"
}

export interface IUserEvent<T=any> {
    type: UserEventType;
    userId: string;
    name?: string;
    /**
     * Where there is no known user, we typically generate a client
     * ID for action correlation.
     */
    clientId?: string;
    data?: T;
}

export interface IViewedFullscreenImagesEventData {
    product: Partial<IProduct>;
}

export interface IViewedProductPageTabEventData {
    product: Partial<IProduct>;
    tabName: string;
}

export interface IUserEventDescriptionData {
    description: string;
}

export interface IProductSearchEventData {
    query?: string;
}

export enum CustomerEventType {
    /**
     * Raised when a customer record is updated.
     */
    DetailsUpdated="details_updated",
    /**
     * Raised when an industry reference for a pro account application is submitted by
     * a customer.
     */
    ProAccountReferenceSubmitted="pro_account.reference_submitted",
    /**
     * Raised when a payment card is added to a customer account.
     */
    CardAddedToAccount="card_added_to_account",
    /**
     * Raised when a payment card is removed from a customer account.
     */
    CardRemovedFromAccount="card_removed_from_account",
    /**
     * Raised when the customer account type (standard, pro) has changed.
     */
    AccountTypeChanged="account_type.changed"
}

export interface ICustomersEvent<T=unknown> {
    type: CustomerEventType;
    accountId: string;
    accountName?: string;
    data?: T;
}

export enum JobEventType {
    JobCreated="job.created",
    JobUpdated="job.updated",
    JobDeleted="job.deleted",
    NotesUpdated="notes.updated"
}

export interface IJobEvent {
    type: JobEventType;
    jobReference: string;
    /**
     * The Keycloak user ID (sub in OAuth access token) who
     * performed the action.
     */
    actorId: string;
}

export enum LocationEventType {
    ClosureAdded="closure.added",
    ClosureDeleted="closure.deleted",
    ClosureUpdated="closure.updated"
}

export interface ILocationEvent<T=unknown> {
    type: LocationEventType;
    data?: T;
}