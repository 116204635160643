import {ButtonProps, CircularProgress} from "@material-ui/core";
import StandardButton from "./standardButton";
import React, {useCallback, useState} from "react";

interface IProgressButtonProps extends ButtonProps {
    loading?: boolean;
}

export const ProgressButton = React.forwardRef<HTMLButtonElement, IProgressButtonProps>(({loading=false, size, onClick, ...props}, ref) => {
    const [working, setWorking] = useState<boolean>(false);

    const handleClick = useCallback(async (ev: React.MouseEvent<HTMLButtonElement>) => {
        setWorking(true);
        try {
            await onClick?.(ev);
        }
        finally {
            setWorking(false);
        }
    }, [onClick]);

    return (
        <StandardButton
            {...props}
            ref={ref}
            size={size}
            onClick={handleClick}
            disabled={loading || props.disabled || working}
            startIcon={(loading || working) ? <CircularProgress color={"inherit"} size={!size ? 20 : (size === "small" ? 16 : 22)} /> : props.startIcon}>
            {props.children}
        </StandardButton>
    )
})

ProgressButton.displayName = "ProgressButton";
export default ProgressButton;