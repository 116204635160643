import {Button, ButtonProps} from "@material-ui/core";
import Routes from "../../routes";
import Link from "next/link";
import React from "react";

interface IProps {
    text?: string;
    url?: string;
    onClick?: () => void;
    size?: ButtonProps["size"];
}

export default function HelpButton({text="Help", url=Routes.Help.TaggedHelp, onClick, ...props}: IProps) {
    return (
        <Link href={url} passHref={true} prefetch={false}>
            <Button variant={"outlined"} color={"secondary"} component={"a"} onClick={onClick} {...props}>
                {text}
            </Button>
        </Link>
    )
}