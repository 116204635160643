import React, {useCallback, useContext, useMemo} from "react";
import {AuthContext} from "./authContext";
import {useSocket} from "use-socketio";
import {Socket} from "socket.io-client";
import {
    IClientEvent,
    IUserEventDescriptionData,
    IUserEvent,
    UserEventType, IProductSearchEventData
} from "../events";
import {StorageKeys, Topics} from "../constants";
import * as Sentry from '@sentry/nextjs';

interface IActionContext {
    emitAction: (description?: string) => Promise<void>;
    reportSearch: (query?: string) => Promise<void>;
}

export const ActionContext = React.createContext<IActionContext>({
    emitAction: async () => null,
    reportSearch: async () => null
});

/**
 * Provides a context through which user actions can be emitted as events via the event broker,
 * where they can be used by downstream clients.
 *
 * @param children
 * @constructor
 */
const ActionContextProvider: React.FC = ({children}) => {
    const {user} = useContext(AuthContext);
    const {socket}: {socket: Socket} = useSocket();

    const emitClientEvent = useCallback(async (description: string) => {
        try {
            const noTrack = window.localStorage.getItem(StorageKeys.NoUserActionTracking);
            if(noTrack) {
                return;
            }

            const event: IClientEvent<IUserEvent<IUserEventDescriptionData>> = {
                topic: Topics.Users,
                propagateToPubSub: true,
                clientId: socket?.id,
                data: {
                    type: UserEventType.CustomerAppAction,
                    userId: user?.id,
                    name: user?.fullName,
                    clientId: socket?.id,
                    data: {
                        description
                    }
                }
            };
            socket.emit(Topics.ClientEvent, event);
        }
        catch(exc) {
            Sentry.captureException(exc);
        }
    }, [socket, user]);

    const reportSearch = useCallback(async (query: string) => {
        try {
            const noTrack = window.localStorage.getItem(StorageKeys.NoUserActionTracking);
            if(noTrack) {
                return;
            }

            const event: IClientEvent<IUserEvent<IProductSearchEventData>> = {
                topic: Topics.Users,
                propagateToPubSub: true,
                clientId: socket?.id,
                data: {
                    type: UserEventType.ProductSearch,
                    userId: user?.id,
                    name: user?.fullName,
                    clientId: socket?.id,
                    data: {
                        query
                    }
                }
            };
            socket.emit(Topics.ClientEvent, event);
        }
        catch(exc) {
            Sentry.captureException(exc);
        }
    }, [socket, user]);

    const context: IActionContext = useMemo(() => {
        return {
            emitAction: emitClientEvent,
            reportSearch
        }
    }, [emitClientEvent, reportSearch]);

    return (
        <ActionContext.Provider value={context}>
            {children}
        </ActionContext.Provider>
    )
}

export default ActionContextProvider;