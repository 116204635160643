import {useCallback, useContext} from "react";
import {AuthContext} from "../../context/authContext";
import ProgressButton from "../buttons/progressButton";
import {VpnKey} from "@material-ui/icons";
import {StatusContext} from "../../context/statusContext";


export default function PasskeySignInButton() {
    const {signInWithAuthenticator} = useContext(AuthContext);
    const {setStatus} = useContext(StatusContext);

    const handleClick = useCallback(async () => {
        setStatus(undefined);
        const success = await signInWithAuthenticator();
        if(!success) {
            setStatus("Failed to sign in. Please ensure you are using a Passkey/FIDO2 compliant authenticator.", "critical");
        }
    }, [signInWithAuthenticator, setStatus]);

    return (
        <ProgressButton onClick={handleClick} startIcon={<VpnKey/>}>
            Use A Passkey
        </ProgressButton>
    )
}