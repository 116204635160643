import React, {useCallback, useEffect, useRef, useState} from "react";
import {useRouter} from "next/router";
import dynamic from "next/dynamic";

interface IMenuContext {
    openMainMenu: (open: boolean) => void;
}

export const MenuContext = React.createContext<IMenuContext>({
    openMainMenu: () => null
});

const MenuDialog = dynamic(() => import("../components/dialogs/mainMenu/menuDialog"));

const MenuContextProvider: React.FC = ({children}) => {
    const router = useRouter();
    const [open, setOpen] = useState<boolean>(false);
    const [opened, setOpened] = useState<boolean>(false);

    const handleOpenMenu = useCallback(() => {
        setOpen(true);
        setOpened(true);
    }, []);

    const context = useRef<IMenuContext>({
        openMainMenu: handleOpenMenu
    });

    /**
     * Make sure the menu closes when a client side navigation occurs, or else
     * the route will change and the menu will obscure the new page.
     */
    useEffect(() => {
        const handleRouteChange = () => {
            setOpen(false);
        }
        router.events.on("routeChangeComplete", handleRouteChange);

        return () => {
            router.events.off("routeChangeComplete", handleRouteChange);
        }
    }, [router]);

    const handleClose = useCallback(() => {
        setOpen(false);
    }, []);

    return (
        <MenuContext.Provider value={context.current}>
            {children}
            {opened && (
                <MenuDialog
                    isOpen={open}
                    onClose={handleClose} />
            )}
        </MenuContext.Provider>
    )
};

export default MenuContextProvider;