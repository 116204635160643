import {gql} from "@apollo/client";

export const CUSTOMER_FRAGMENT = gql`
    fragment CustomerData on Customer {
        id
        name
        accountType
        address {
            street
            streetTwo
            city
            postCode
            state
            country
            googlePlaceId
        }
        proAccountApplication {
            status
            references {
                id
                name
                phone
                email
            }
            customerNotes
        }
    }
`;

export const AUTHENTICATE_WITH_PW = gql`
    mutation Authenticate($email: String!, $password: String!, $context: String) {
        authenticate(username: $email, password: $password, context: $context) {
            success
            token
        }
    }
`;

export const AUTHENTICATE_WITH_IDP_CODE = gql`
    mutation AuthenticateWithIdpCode($code: String!, $provider: String!, $options: IdpOptions, $context: String) {
        authenticateWithIdpCode(code: $code, provider: $provider, options: $options, context: $context) {
            success
            token
            failureReason
            identityProfile {
                email
                firstName
                lastName
                picture
            }
        }
    }
`;

export const AUTHENTICATE_WITH_IDP_ID_TOKEN = gql`
    mutation AuthenticateWithIdpIdToken($token: String!, $context: String) {
        authenticateWithIdpIdToken(token: $token, context: $context) {
            success
            token
            failureReason
            identityProfile {
                email
                firstName
                lastName
                picture
            }
        }
    }
`;

export const GET_IDP_PROFILE = gql`
    query GetIdpProfile($code: String!, $options: IdpOptions!) {
        getIdpProfile(code: $code, options: $options) {
            firstName
            lastName
            name
            email
            emailVerified
            localAccountId
            idToken
        }
    }
`;

export const SEND_PASSWORD_RESET_EMAIL = gql`
    mutation SendPasswordResetEmail($email: String!, $postRedirect: String) {
        sendPasswordResetEmail(emailAddress: $email, postRedirect: $postRedirect) {
            success
        }
    }
`;

export const GET_USER_WITH_PASSWORD_RESET_TOKEN = gql`
    query GetUserWithPasswordResetToken($token: String!) {
        getUserWithPasswordResetToken(token: $token) {
            id
            firstName
        }
    }
`

export const USER_FRAGMENT = gql`
    fragment UserData on User {
        id
        firstName
        lastName
        idVerified
        enabled
        mobileNumber
        email
        profile {
            mustProvideId
            mobileValidation {
                isValidated
            }
            requiredDocuments {
                id
                type
                status
            }
            favouriteLists {
                id
                name
                order
                favourites {
                    id
                    quantity
                    product {
                        id
                        slug
                        name
                        summary
                        heroImage {
                            id
                            publicId
                            altText
                            height
                            width
                        }
                        pricing {
                            dayRate
                            weekRate
                        }
                    }
                    order
                }
            }
        }
        customerAccounts {
            ...CustomerData
        }
        credentials {
            id
            type
        }
    }
    ${CUSTOMER_FRAGMENT}
`;

export const CURRENT_USER = gql`
    query CurrentUser {
        me {
            ...UserData
        }
    }
    ${USER_FRAGMENT}
`

export const UPDATE_USER = gql`
    mutation UpdateUser($userId: String!, $details: UserInput) {
        updateUser(userId: $userId, details: $details) {
            ...UserData
        }
    }
    ${USER_FRAGMENT}
`

export const SET_PASSWORD_WITH_RESET_TOKEN = gql`
    mutation SetPasswordWithResetToken($password: String!, $token: String!) {
        setPasswordWithResetToken(password: $password, resetToken: $token) {
            success
            token
            failureReason
        }
    }
`;

export const CREATE_ACCOUNT = gql`
    mutation CreateAccount($input: CreateAccountInput) {
        createAccount(input: $input) {
            success
            token
        }
    }
`

export const ADD_CUSTOMER_ACCOUNT = gql`
    mutation AddCustomerAccount($accountName: String!) {
        addCustomerAccount(accountName: $accountName) {
            ...CustomerData
        }
    }
    ${CUSTOMER_FRAGMENT}
`;

export const ATTESTATION_CHALLENGE = gql`
    mutation AttestationChallenge {
        attestationChallenge {
            response
        }
    }
`;

export const ATTESTATION_VERIFICATION = gql`
    mutation AttestationVerification($input: JSONObject) {
        attestationVerification(input: $input) {
            success
        }
    }  
`;

export const ASSERTION_OPTIONS = gql`
    query AssertionOptions {
        assertionOptions {
            response
        }
    }
`;

export const ASSERTION_VERIFICATION = gql`
    mutation AssertionVerification($input: JSONObject) {
        assertionVerification(input: $input) {
            success
            token
        }
    }
`;

export const REGISTERED_DEVICES = gql`
    query RegisteredDevices {
        registeredDevices {
            id
            nickname
        }
    }
`

export const UPDATE_DEVICE = gql`
    mutation UpdateDevice($input: UpdateDeviceInput) {
        updateSecurityDevice(input: $input) {
            id
            nickname
        }
    }
`
export const JOB_FRAGMENT = gql`
    fragment JobData on Job {
        id
        reference
        status
        customerReference
        starts
        ends
        confirmationCutoff
        collectionTime
        returnTime
        durationDays
        daysCharged
        standardDaysCharged
        totalBeforeTax
        taxAmount
        total
        balanceOwed
        damageWaiverApplies
        damageWaiverAmount
        discountAmount
        customer {
            id
            name
        }
        onBehalfOfUser {
            id
            firstName
            lastName
        }
        lines {
            id
            quantity
            rate
            description
            lineTotal
            status
            product {
                id
                name
                slug
                pricing {
                    dayRate
                    weekRate
                }
                quantityStocked
                heroImage {
                    id
                    altText
                    publicId
                    height
                    width
                }
                nestedProducts {
                    quantity
                    product {
                        id
                        name
                    }
                }
                accessories {
                    id
                    quantity
                    name
                    visibility
                }
                visibilityLevel
            }
        }
        invoiceDueDate
        invoiceTo {
            customer {
                id
                name
            }
            recipients {
                emailAddress
            }
        }
        productsRemovedDueToInsufficientStock {
            quantity
            product {
                id
                name
            }
        }
        promoCode
        createdBy {
            userId
            name
        }
        createdAt
    }  
`

export const GET_JOB = gql`
    query GetJob($input: GetJobInput!, $shortfall: Boolean = false, $confirmStatus: Boolean = false, $cancelStatus: Boolean = false) {
        getJob(input: $input) {
            ...JobData
            damageWaiverRequired
            shortfall @include(if: $shortfall) {
                productId
                product {
                    name
                }
                quantity
                maximumForThisJob
            }
            canConfirmStatus @include(if: $confirmStatus) {
                canBeConfirmed
                reason
            }
            canCancelStatus @include(if: $cancelStatus) {
                canBeCancelled
                canBeCancelledWithoutCharge
            }
        }
    }
    ${JOB_FRAGMENT}
`;

export const CANCEL_JOB = gql`
    mutation CancelJob($jobKey: GetJobInput!, $options: CancelJobOptions) {
        cancelJob(jobKey: $jobKey, options: $options) {
            ...JobData
            canConfirmStatus {
                canBeConfirmed
                reason
            }
            canCancelStatus {
                canBeCancelled
                canBeCancelledWithoutCharge
            }
        }
    }
    ${JOB_FRAGMENT}
`;

export const JOB_STOCK_SHORTFALL = gql`
    query JobStockShortfall($jobKey: GetJobInput!) {
        jobStockShortfall(jobKey: $jobKey) {
            productId
            quantity
            maximumForThisJob
        }
    }
`

export const JOB_HISTORY = gql`
    query JobHistory($customerId: String!) {
        getCustomerJobs(customerId: $customerId) {
            id
            reference
            customerReference
            status
            starts
            ends
            durationDays
            total
        }
    }
`

export const UPSERT_JOB = gql`
    mutation UpsertJob($input: JobInput, $shortfall: Boolean = false, $confirmStatus: Boolean = false) {
        upsertJob(input: $input) {
            ...JobData
            damageWaiverRequired
            shortfall @include(if: $shortfall) {
                productId
                quantity
                maximumForThisJob
                product {
                    name
                }
            }
            canConfirmStatus @include(if: $confirmStatus) {
                canBeConfirmed
                reason
            }
        }
    }
    ${JOB_FRAGMENT}
`;

export const CONFIRM_JOB = gql`
    mutation ConfirmJob($jobKey: GetJobInput!, $options: ConfirmJobOptions) {
        confirmJob(jobKey: $jobKey, options: $options) {
            ...JobData
        }
    }
    ${JOB_FRAGMENT}
`

export const DECLINE_JOB = gql`
    mutation DeclineJob($input: GetJobInput) {
        declineJob(input: $input) {
            ...JobData
        }
    }
    ${JOB_FRAGMENT}
`;

export const GET_QUOTE_DOCUMENT = gql`
    query GetQuoteDocument($jobReference: String!) {
        getQuoteDocument(jobReference: $jobReference) {
            name
            signedUrl
        }
    }
`;

export const GET_INVOICE_DOCUMENT = gql`
    query GetInvoiceDocument($jobReference: String!) {
        getInvoiceDocument(jobReference: $jobReference) {
            name
            signedUrl
        }
    }
`;

export const SEND_QUOTE_EMAIL = gql`
    mutation SendQuoteEmail($input: SendQuoteInput) {
        sendQuote(input: $input) {
            id
        }
    }
`

export const GET_JOB_PAYMENT_INTENT = gql`
    query GetJobPaymentIntent($jobReference: String!) {
        getJobPaymentIntent(jobReference: $jobReference) {
            clientSecret
            amountInCents
        }
    }
`;

export const PAYMENT_URL = gql`
    query PaymentUrl($jobReference: String!) {
        getJobPaymentUrl(jobReference: $jobReference)
    }
`;

export const SEND_PAYMENT_RECEIPT = gql`
    mutation SendPaymentReceipt($input: SendPaymentReceiptInput!) {
        sendPaymentReceipt(input: $input) {
            success
        }
    }
`;

export const DELETE_PAYMENT_CARD = gql`
    mutation DeletePaymentCard($customerId: String!, $cardId: String!) {
        deletePaymentCard(customerId: $customerId, cardId: $cardId) {
            success
        }
    }
`;

export const GET_CUSTOMER = gql`
    query GetCustomer($id: String!) {
        customer(id: $id) {
            ...CustomerData
        }
    }
    ${CUSTOMER_FRAGMENT}
`;

export const GET_CUSTOMER_SETUP_INTENT = gql`
    query GetCustomerSetupIntent($customerId: String!) {
        customerSetupIntent(customerId: $customerId) {
            clientSecret
        }
    }
`;

export const CUSTOMER_PAYMENT_CARDS = gql`
    query CustomerPaymentCards($customerId: String!) {
        getCustomerPaymentCards(customerId: $customerId) {
            id
            brand
            cardholderName
            expiryMonth
            expiryYear
            lastFour
        }
    }
`;

export const UPDATE_CUSTOMER = gql`
    mutation UpdateCustomer($input: CustomerInput) {
        upsertCustomer(customer: $input) {
            customer {
                ...CustomerData                
            }
        }
    }
    ${CUSTOMER_FRAGMENT}
`;

export const GET_PRODUCT = gql`
    query GetProduct($productId: String, $productSlug: String) {
        getProduct(productId: $productId, productSlug: $productSlug) {
            id
            slug
            name
            summary
            description
            quantityStocked
            visibilityLevel
            pricing {
                dayRate
                weekRate
            }
            heroImage {
                id
                altText
                publicId
                height
                width
                originalUrl
            }
            otherImages {
                id
                altText
                publicId
                height
                width
            }
            specifications {
                id
                title
                sections {
                    id
                    title
                    body
                }
            }
            videos {
                id
                title
                caption
                videoId
            }
            articles {
                id
                slug
                title
                summary
                activeVersion {
                    id
                    readingTime
                }
                published
                heroImage {
                    id
                    publicId
                    altText
                    width
                    height
                }
                authors {
                    id
                    firstName
                    lastName
                    email
                }
            }
            categoryObjects {
                id
                name
                slug
                rootId
                hierarchy {
                    id
                    name
                    slug
                }
            }
            nestedProducts {
                quantity
                product {
                    id
                    name
                }
            }
            accessories {
                id
                quantity
                name
                visibility
            }
            relatedProducts(filters: {
                visibility: [ANYONE, PRO_ACCOUNTS],
                status: [AVAILABLE]
            }) {
                product {
                    id
                    slug
                    name,
                    heroImage {
                        id
                        altText
                        publicId
                        height
                        width
                    }
                    hasSummary
                    hasDescription
                    otherImageCount
                }
            }
            attributeValues {
                id
                label
                order
                values {
                    id
                    value
                    order
                }
            }
        }
    }
`;

export const GET_PRODUCTS_FOR_QUOTE = gql`
    query GetProductsForQuote($keys: [ProductKey!]!, $filters: ProductFilterInput) {
        getProducts(keys: $keys, filters: $filters) {
            id
            slug
            name
            heroImage {
                id
                altText
                publicId
                height
                width
            }
            pricing {
                dayRate
                weekRate
            }
            quantityStocked
            visibilityLevel
            nestedProducts {
                quantity
                product {
                    id
                    name
                }
            }
            accessories {
                id
                quantity
                name
                visibility
            }
        }
    }
`;

export const PRODUCTS_WITH_ATTRIBUTES = gql`
    query ProductsWithAttributes($input: GetProductsWithAttributesInput) {
        getProductsWithAttributes(input: $input) {
            id
            name
            slug
            summary
            description
            heroImage {
                id
                altText
                publicId
                height
                width
            }
            otherImages {
                id
                publicId
            }
        }
    }
`;

export const ALL_PRODUCT_CATEGORIES = gql`
    query AllProductCategories {
        getProductCategories {
            id
            slug
            name
            summary
            heroImage {
                id
                publicId
                altText
            }
            children
        }
    }
`;

export const GET_PRODUCT_CATEGORY = gql`
    query GetProductCategory($categoryId: String, $categorySlug: String, $includeChildren: Boolean = false) {
        getProductCategory(categoryId: $categoryId, categorySlug: $categorySlug, includeChildren: $includeChildren) {
            id
            name
            slug
            summary
            code
            children
            hierarchy {
                id
                name
                slug
            }
        }
    }
`;

export const PRODUCTS_IN_CATEGORY = gql`
    query ProductsInCategory($input: GetProductsInCategoryInput) {
        getProductsInCategory(input: $input) {
            id
            name
            slug
            summary
            description
            pricing {
                dayRate
                weekRate
            }
            quantityStocked
            heroImage {
                id
                altText
                publicId
                height
                width
                originalUrl
            }
            otherImages {
                id
                altText
                publicId
                height
                width
            }
            nestedProducts {
                quantity
                product {
                    id
                    name
                }
            }
            accessories {
                id
                quantity
                name
                visibility
            }
            visibilityLevel
            attributeValues {
                id
                label
                order
                values {
                    id
                    value
                    order
                }
            }
        }
    }
`;

export const SEARCH_PRODUCTS = gql`
    query SearchProducts($query: String!) {
        searchProducts(query: $query) {
            id
            name
            slug
            visibilityLevel
            hasSummary
            hasDescription
            heroImage {
                id
                publicId
            }
            otherImageCount
            rootCategories {
                id
                name
                slug
            }
        }
    }
`

export const BUSINESS_CLOSURES = gql`
    query BusinessClosures($input: ClosureFilterInput) {
        businessClosures(input: $input) {
            id
            name
            starts
            ends
        }
    }
`;

export const EARLIEST_JOB_DATE = gql`
    query EarliestJobDate {
        earliestJobDate {
            earliest
            cutoff
        }
    }
`

export const ARTICLE_FRAGMENT = gql`
    fragment ArticleFragment on Article {
        id
        title
        summary
        slug
        text
        status
        published
        lastUpdated
        activeVersion {
            id
            readingTime
        }
        heroImage {
            id
            publicId
            width
            height
        }
        tags {
            id
            slug
            name
            description
        }
        authors {
            id
            firstName
            lastName
            email
        }
    }
`;

export const GET_ARTICLE = gql`
    query GetArticle($slug: String, $id: String) {
        article(slug: $slug, id: $id) {
            ...ArticleFragment
        }
    }
    ${ARTICLE_FRAGMENT}
`;

export const GET_ARTICLES = gql`
    query GetArticles($filters: ArticleFilterInput) {
        articles(filters: $filters) {
            id
            title
            summary
            slug
            published
            heroImage {
                id
                publicId
                altText
                width
                height
            }
            activeVersion {
                id
                readingTime
            }
            tags {
                id
                slug
                name
            }
            authors {
                id
                firstName
                lastName
                email
            }
        }
    }
`;

export const SEARCH_ARTICLES = gql`
    query SearchArticles($query: String!) {
        searchArticles(query: $query) {
            id
            title
            slug
            summary
        }
    }
`;

export const GET_ARTICLE_TAGS = gql`
    query GetArticleTags($ids: [String!], $slugs: [String!]) {
        articleTags(ids: $ids, slugs: $slugs) {
            id
            slug
            name
            description
        }
    }
`;

export const NOTICES = gql`
    query Notices {
        currentNotices {
            id
            title
            message
        }
    }
`;

export const SEND_MOBILE_VALIDATION = gql`
    mutation SendMobileValidation {
        sendMobileValidation
    }
`;

export const VALIDATE_MOBILE_WITH_CODE = gql`
    mutation ValidateMobileWithCode($code: String!) {
        validateMobileWithCode(code: $code)
    }
`;