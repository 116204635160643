/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AccessoryVisibility {
  QUOTES = "QUOTES",
  RA = "RA",
}

export enum ApplicationStatus {
  SUBMITTED = "SUBMITTED",
}

export enum ArticleStatus {
  archived = "archived",
  draft = "draft",
  published = "published",
}

export enum ClosureStatus {
  confirmed = "confirmed",
  draft = "draft",
  proposed = "proposed",
}

export enum IdentityProvider {
  apple = "apple",
  facebook = "facebook",
  google = "google",
}

export enum JobLineStatus {
  HOLDING = "HOLDING",
  NOT_HOLDING = "NOT_HOLDING",
}

export enum JobStatus {
  CANCELLED = "CANCELLED",
  COLLECTED = "COLLECTED",
  COMPLETED = "COMPLETED",
  CONFIRMED = "CONFIRMED",
  DECLINED = "DECLINED",
  DRAFT = "DRAFT",
  NEEDS_RESOLUTION = "NEEDS_RESOLUTION",
  PREPARED = "PREPARED",
  PREPARING = "PREPARING",
  QUOTED = "QUOTED",
  QUOTING = "QUOTING",
  RETURN_CHECKING = "RETURN_CHECKING",
}

export enum ProductStatus {
  ARCHIVED = "ARCHIVED",
  AVAILABLE = "AVAILABLE",
  DRAFT = "DRAFT",
}

export enum ProductVisibility {
  ANYONE = "ANYONE",
  PRO_ACCOUNTS = "PRO_ACCOUNTS",
  STAFF = "STAFF",
}

export enum RequiredDocumentStatus {
  NEEDS_REVIEW = "NEEDS_REVIEW",
  REQUESTED = "REQUESTED",
}

export enum RequiredDocumentType {
  ADDRESS = "ADDRESS",
  CITIZENSHIP = "CITIZENSHIP",
  PHOTO_ID = "PHOTO_ID",
}

export interface AccountApplicationNoteInput {
  id?: string | null;
  body?: string | null;
  delete?: boolean | null;
  remove?: boolean | null;
}

export interface ArticleFilterInput {
  recent?: boolean | null;
  ids?: string[] | null;
  slugs?: string[] | null;
  tagIds?: string[] | null;
  authorIds?: (string | null)[] | null;
  statuses?: (ArticleStatus | null)[] | null;
  limit?: number | null;
}

export interface CancelJobOptions {
  sendCancellationEmail?: boolean | null;
}

export interface ClosureFilterInput {
  periodStart?: any | null;
  periodEnd?: any | null;
  status?: (ClosureStatus | null)[] | null;
}

export interface ConfirmJobOptions {
  sendConfirmationEmail?: boolean | null;
}

export interface CreateAccountInput {
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  mobileNumber?: string | null;
  businessName?: string | null;
  context?: string | null;
}

export interface CustomerInput {
  id?: string | null;
  name?: string | null;
  contactName?: string | null;
  phoneNumber?: string | null;
  emailAddress?: string | null;
  street?: string | null;
  streetTwo?: string | null;
  city?: string | null;
  postCode?: string | null;
  state?: string | null;
  country?: string | null;
  googlePlaceId?: string | null;
  paymentTerms?: string | null;
  accountType?: string | null;
  alert?: string | null;
  proAccountApplication?: ProAccountApplicationInput | null;
}

export interface FavouriteInput {
  id?: string | null;
  quantity?: number | null;
  productId?: string | null;
  order?: number | null;
  delete?: boolean | null;
}

export interface FavouriteListInput {
  id?: string | null;
  name?: string | null;
  favourites?: (FavouriteInput | null)[] | null;
  order?: number | null;
  delete?: boolean | null;
}

export interface GetJobInput {
  id?: string | null;
  reference?: string | null;
}

export interface GetProductsInCategoryInput {
  categoryId?: string | null;
  categorySlug?: string | null;
  includeSubCategories?: boolean | null;
  visibility?: (ProductVisibility | null)[] | null;
  status?: (ProductStatus | null)[] | null;
}

export interface GetProductsWithAttributesInput {
  attributeIds: (string | null)[];
  restrictToCategories?: (string | null)[] | null;
  visibility?: (ProductVisibility | null)[] | null;
  status?: (ProductStatus | null)[] | null;
}

export interface IdpOptions {
  provider?: IdentityProvider | null;
  redirectUri?: string | null;
}

export interface InvoiceRecipientInput {
  id?: string | null;
  email?: string | null;
  remove?: boolean | null;
}

export interface InvoiceToInput {
  customerId?: string | null;
  recipients?: (InvoiceRecipientInput | null)[] | null;
  replaceRecipients?: boolean | null;
}

export interface JobInput {
  id?: string | null;
  reference?: string | null;
  customerId?: string | null;
  onBehalfOfUserId?: string | null;
  status?: JobStatus | null;
  starts?: any | null;
  ends?: any | null;
  daysCharged?: number | null;
  confirmationCutoff?: any | null;
  collectionTime?: any | null;
  returnTime?: any | null;
  customerReference?: string | null;
  discount?: string | null;
  damageWaiverApplies?: boolean | null;
  insuredByCustomer?: boolean | null;
  taxPercentage?: number | null;
  duplicateOfBookingReference?: string | null;
  lines?: (JobLineInput | null)[] | null;
  notes?: (JobNoteInput | null)[] | null;
  quoteRecipients?: (QuoteRecipientInput | null)[] | null;
  replaceQuoteRecipients?: boolean | null;
  invoiceTo?: InvoiceToInput | null;
  assignedToUserId?: string | null;
  removeProductsWithInsufficientStock?: boolean | null;
  promoCode?: string | null;
}

export interface JobLineInput {
  id?: string | null;
  status?: JobLineStatus | null;
  productId?: string | null;
  description?: string | null;
  quantity?: number | null;
  rate?: number | null;
  order?: number | null;
  remove?: boolean | null;
  useCurrentRate?: boolean | null;
}

export interface JobNoteInput {
  id?: string | null;
  body?: string | null;
  pinned?: boolean | null;
  delete?: boolean | null;
}

export interface ProAccountApplicationInput {
  references?: (ReferenceInput | null)[] | null;
  customerNotes?: string | null;
  internalNotes?: (AccountApplicationNoteInput | null)[] | null;
}

export interface ProductFilterInput {
  visibility?: (ProductVisibility | null)[] | null;
  status?: (ProductStatus | null)[] | null;
}

export interface ProductKey {
  id?: string | null;
  slug?: string | null;
}

export interface QuoteRecipientInput {
  id?: string | null;
  email?: string | null;
  remove?: boolean | null;
}

export interface ReferenceInput {
  id?: string | null;
  name?: string | null;
  phone?: string | null;
  email?: string | null;
  remove?: boolean | null;
}

export interface RequiredDocumentInput {
  id?: string | null;
  type?: RequiredDocumentType | null;
  status?: RequiredDocumentStatus | null;
  delete?: boolean | null;
}

export interface SendPaymentReceiptInput {
  jobReference: string;
  recipients: string[];
}

export interface SendQuoteInput {
  jobReference: string;
  filename: string;
  recipients: string[];
  subject: string;
  body: string;
}

export interface UpdateDeviceInput {
  deviceId: string;
  userId?: string | null;
  remove?: boolean | null;
  nickname?: string | null;
}

export interface UserInput {
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  mobileNumber?: string | null;
  password?: string | null;
  deletePassword?: boolean | null;
  enabled?: boolean | null;
  idVerified?: boolean | null;
  alert?: string | null;
  requiredDocuments?: (RequiredDocumentInput | null)[] | null;
  favouriteLists?: (FavouriteListInput | null)[] | null;
  mustProvideId?: boolean | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
