import React from "react";
import {Button, ButtonProps} from "@material-ui/core";

const StandardButton = React.forwardRef<HTMLButtonElement, ButtonProps>(({children, ...props}, ref) => {
    return (
        <Button {...props} variant={"outlined"} color={"secondary"} ref={ref}>
            {children}
        </Button>
    )
});

StandardButton.displayName = "StandardButton";

export default StandardButton;