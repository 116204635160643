import {useMemo} from 'react';
import {ApolloClient, ApolloLink, HttpLink, InMemoryCache, NormalizedCacheObject} from '@apollo/client';
import {setContext} from "@apollo/client/link/context";
import {RetryLink} from "@apollo/client/link/retry";
import {applicationUser} from "./cache";
import {AUTH_STORAGE_KEY, removeAccessToken, validateAccessToken} from "./context/authContext";
import {onError} from "@apollo/client/link/error";
import Logger from "./logger";
import {parseISO} from "date-fns";
import Errors from "./errors";

let apolloClient: ApolloClient<NormalizedCacheObject>;

export function createApolloClient() {
    const httpLink = new HttpLink({
        uri: process.env.NEXT_PUBLIC_API_GATEWAY
    });

    const authLink = setContext((_, {headers}) => {
        if(typeof window === "undefined") {
            return headers;
        }

        const token = localStorage?.getItem(AUTH_STORAGE_KEY);
        return {
            headers: {
                ...headers,
                authorization: !!token ? `Bearer ${token}` : "",
            }
        }
    });

    const retryLink = new RetryLink();

    const errorLink = onError(({graphQLErrors}) => {
        if(typeof window === "undefined") {
            return;
        }

        let alreadySignedOut = false;
        if (graphQLErrors) {
            for (let err of graphQLErrors) {
                //
                // We need to look out for an unauthenticated response from the server.
                // Upon getting such an error, we want to ensure we don't think we have
                // an authenticated user set for the app. Here we are using a reactive
                // variable and by setting it to null, the auth context provider we
                // use throughout the app will pass null down to all components - thus
                // ensuring their state is updated in response to there not being a signed
                // in user.
                //
                if ([Errors.Unauthenticated].includes(err.extensions?.exception?.code ?? "")) {
                    Logger.info("Got an unauthenticated response from the API GW. Signing the user out.")
                    removeAccessToken();
                    applicationUser(null);
                    alreadySignedOut = true;
                    break;
                }
            }
        }

        //
        // Whenever we get an error, ensure we have a valid JWT or else set the user
        // to null in order to update the component tree state as required.
        //
        if(!alreadySignedOut) {
            const token = localStorage?.getItem(AUTH_STORAGE_KEY);
            if(!token) {
                applicationUser(null);
            }
            else {
                validateAccessToken(token).then(result => {
                    if(!result) {
                        applicationUser(null);
                    }
                });
            }
        }
    });

    return new ApolloClient({
        connectToDevTools: process.env.NODE_ENV === "development",
        ssrMode: typeof window === 'undefined',
        link: ApolloLink.from([
            errorLink,
            retryLink,
            authLink,
            httpLink
        ]),
        cache: new InMemoryCache({
            typePolicies: {
                AssignedProductAttribute: {
                    // This disables denormalisation on this type, which we need to do
                    // for product filtering within a category.
                    keyFields: false
                },
                User: {
                    fields: {
                        //
                        // Always override a user's credentials collection using data returned
                        // from a new fetch.
                        //
                        credentials: {
                            merge: (existing, incoming) => incoming
                        }
                    }
                },
                Creator: {
                    keyFields: ["userId"]
                },
                Closure: {
                    fields: {
                        starts: {
                            read (value) {
                                if(value) {
                                    try {
                                        return parseISO(value);
                                    }
                                    catch (exc) {
                                        return null;
                                    }
                                }
                                return null;
                            }
                        },
                        ends: {
                            read (value) {
                                if(value) {
                                    try {
                                        return parseISO(value);
                                    }
                                    catch (exc) {
                                        return null;
                                    }
                                }
                                return null;
                            }
                        }
                    }
                },
                Job: {
                    fields: {
                        starts: {
                            read (value) {
                                if(value) {
                                    try {
                                        return parseISO(value);
                                    }
                                    catch (exc) {
                                        return null;
                                    }
                                }
                                return null;
                            }
                        },
                        ends: {
                            read (value) {
                                if(value) {
                                    try {
                                        return parseISO(value);
                                    }
                                    catch (exc) {
                                        return null;
                                    }
                                }
                                return null;
                            }
                        },
                        invoiceDueDate: {
                            read (value) {
                                if(value) {
                                    try {
                                        return parseISO(value);
                                    }
                                    catch (exc) {
                                        return null;
                                    }
                                }
                                return null;
                            }
                        },
                        createdAt: {
                            read (value) {
                                if(value) {
                                    try {
                                        return parseISO(value);
                                    }
                                    catch (exc) {
                                        return null;
                                    }
                                }
                                return null;
                            }
                        },
                        confirmationCutoff: {
                            read (value) {
                                if(value) {
                                    try {
                                        return parseISO(value);
                                    }
                                    catch (exc) {
                                        return null;
                                    }
                                }
                                return null;
                            }
                        }
                    }
                }
            }
        })
    })
}

export function initializeApollo(initialState: any = null) {
    const _apolloClient = apolloClient ?? createApolloClient()

    // For SSG and SSR always create a new Apollo Client
    if (typeof window === 'undefined') return _apolloClient;
    // if (typeof window === 'undefined') return createApolloClient();

    // If your page has Next.js data fetching methods that use Apollo Client, the initial state
    // gets hydrated here
    if (initialState) {
        // Get existing cache, loaded during client side data fetching
        const existingCache = _apolloClient.extract();
        // Restore the cache using the data passed from getStaticProps/getServerSideProps
        // combined with the existing cached data
        _apolloClient.cache.restore({...existingCache, ...initialState})
    }

    // Create the Apollo Client once in the client
    if (!apolloClient) apolloClient = _apolloClient;

    return _apolloClient;
}

export function useApollo(initialState: any) {
    return useMemo(() => initializeApollo(initialState), [initialState])
}
