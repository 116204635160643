import React, {useCallback, useContext, useState, Fragment, useEffect, useRef} from "react";
import {FavouritesContext, ProductIdAndName} from "../../context/favouritesContext";
import {
    Box, Checkbox, CircularProgress,
    IconButton,
    Popover,
    TextField,
    Theme,
    Tooltip,
    Typography
} from "@material-ui/core";
import {createStyles, makeStyles} from "@material-ui/styles";
import {Check} from "@material-ui/icons";
import StandardLink from "../links/standardLink";
import Routes from "../../routes";
import {AuthContext} from "../../context/authContext";
import SignInOrCreateAccount from "../signInOrCreateAccount";
import {useRouter} from "next/router";
import {ActionContext} from "../../context/actionContext";

interface IFavouritesPopupProps {
    anchor: HTMLElement|null;
    product: ProductIdAndName|null;
    onClose: () => void;
    onExitTransitionComplete?: () => void;
}

export default function FavouritesPopup({anchor, product, onClose, onExitTransitionComplete}: IFavouritesPopupProps) {
    const classes = styles();
    const router = useRouter();
    const {user} = useContext(AuthContext);
    const {emitAction} = useContext(ActionContext);
    const [newName, setNewName] = useState<string>('');
    const [creating, setCreating] = useState<boolean>(false);
    const {lists, createList, toggleIsFavourite} = useContext(FavouritesContext);
    const ref = useRef<HTMLDivElement>(null);
    const canCreateList = newName?.length > 3;

    /**
     * Make sure the menu closes when a client side navigation occurs, or else
     * the route will change and the menu will obscure the new page.
     */
    useEffect(() => {
        const handleRouteChange = () => {
            onClose();
        }
        router.events.on("routeChangeStart", handleRouteChange);

        return () => {
            router.events.off("routeChangeStart", handleRouteChange);
        }
    }, [router, onClose]);

    useEffect(() => {
        setNewName('');
    }, [anchor]);

    const handleCreateNewList = useCallback(async () => {
        if(!product || !canCreateList) {
            return;
        }
        setCreating(true);
        await createList(newName, [product.id]);
        setCreating(false);
        setNewName('');
        ref.current.scrollTop = ref.current.scrollHeight;
    }, [createList, product, newName, canCreateList]);

    const handleKeyDown = useCallback(async (ev: React.KeyboardEvent) => {
        if(ev.key === "Enter") {
            await handleCreateNewList();
        }
    }, [handleCreateNewList]);

    const handleToggleFavourite = useCallback(async (listId: string) => {
        if(!product) {
            return;
        }
        const added = await toggleIsFavourite(product.id, listId);
        if(added) {
            emitAction(`added ${product?.name} to favourites`).then();
        }
    }, [toggleIsFavourite, product, emitAction]);

    if(!user) {
        return (
            <Popover
                open={!!anchor}
                onClose={onClose}
                anchorEl={anchor}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left"
                }}
                PaperProps={{className: classes.paper}}
                TransitionProps={{
                    onExited: onExitTransitionComplete
                }}
                BackdropProps={{
                    className: classes.backdrop,
                    transitionDuration: 550,
                    timeout: 600
                }}>
                <Box p={3}>
                    <SignInOrCreateAccount />
                </Box>
            </Popover>
        )
    }

    return (
        <Popover
            open={!!anchor}
            onClose={onClose}
            anchorEl={anchor}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left"
            }}
            PaperProps={{className: classes.paper}}
            TransitionProps={{
                onExited: onExitTransitionComplete
            }}
            BackdropProps={{
                className: classes.backdrop,
                transitionDuration: 450,
                timeout: 500
            }}>
            <Box p={2}>
                <Typography variant={"caption"}>
                    Favourites
                </Typography>
                <Box>
                    <Typography className={classes.title}>
                        {product?.name}
                    </Typography>
                </Box>
                {!lists.length && (
                    <Box mt={0.5}>
                        <Typography variant={"body2"} sx={{color: 'text.secondary'}}>
                            You don&lsquo;t have any favourites yet. Enter a
                            name for a new list below and this product
                            will be added to it.
                        </Typography>
                    </Box>
                )}
                {!!lists.length && (
                    <Fragment>
                        <Box mt={0.5}>
                            <Typography variant={"body2"} sx={{color: 'text.secondary'}}>
                                To manage your saved products or add them to a quote, visit
                                your{' '}
                                <StandardLink
                                    onClick={onClose}
                                    href={Routes.Profile.Favourites}>Favourites</StandardLink>
                                {' '}section.
                            </Typography>
                        </Box>
                        <div className={classes.existing} ref={ref}>
                            {lists.map(list => (
                                <div key={list.id} className={classes.list}>
                                    <div>
                                        <Checkbox
                                            color={"secondary"}
                                            onClick={() => handleToggleFavourite(list.id)}
                                            checked={list.favourites.some(fave => fave.product?.id === product?.id)} />
                                    </div>
                                    <div>
                                        <Typography>
                                            {list.name}
                                        </Typography>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </Fragment>
                )}
                <div className={classes.newList}>
                    <div>
                        <TextField
                            disabled={creating}
                            color={"secondary"}
                            variant={"outlined"}
                            size={"small"}
                            onChange={event => setNewName(event.target.value)}
                            onKeyDown={handleKeyDown}
                            value={newName}
                            fullWidth={true}
                            placeholder={"Enter a new list name"} />
                    </div>
                    <div>
                        <Tooltip title={"Save new list"}>
                            <span>
                                <IconButton
                                    onClick={handleCreateNewList}
                                    disabled={creating || !canCreateList}>
                                    {creating ? (
                                          <CircularProgress size={15} />
                                    ) : (
                                        <Check fontSize={"inherit"} />
                                    )}
                                </IconButton>
                            </span>
                        </Tooltip>
                    </div>
                </div>
            </Box>
        </Popover>
    )
}

const styles = makeStyles((theme: Theme) => {
    const darkMode = theme.palette.mode === "dark";
    return createStyles({
        paper: {
            boxShadow: "none",
            minWidth: "100px",
            maxWidth: "350px",
            backgroundColor: darkMode
                ? theme.palette.grey["900"]
                : theme.palette.background.paper,
            border: darkMode ? `dotted 1px ${theme.palette.custom.border}` : `dotted 1px black`
        },
        backdrop: {
            backdropFilter: "blur(8px)",
        },
        title: {
            fontWeight: theme.typography.fontWeightMedium
        },
        existing: {
            margin: theme.spacing(2, 0),
            maxHeight: "250px",
            overflowY: "auto",
            borderBottom: `dotted 1px ${theme.palette.custom.border}`,
            paddingBottom: theme.spacing()
        },
        list: {
            display: "grid",
            gridTemplateColumns: "50px 1fr",
            alignItems: "center"
        },
        newList: {
            marginTop: theme.spacing(2),
            display: "grid",
            gridTemplateColumns: "1fr auto",
            alignItems: "center",
            gap: theme.spacing(),
            "& .MuiIconButton-root": {
                border: `solid 1px ${theme.palette.custom.border}`,
                padding: theme.spacing(0.6)
            }
        }
    })
});