import {Organization} from "schema-dts";

const Config = {
    SrcRoot: __dirname,
    DeploymentUrl: process.env.NEXT_PUBLIC_DEPLOYMENT_URL?.replace(/\/$/, ""),
    EventBrokerHost: process.env.NEXT_PUBLIC_EVENT_BROKER!,
    GoogleOAuthClientId: process.env.NEXT_PUBLIC_GOOGLE_OAUTH_CLIENT_ID,
    AppleSignIn: {
        ClientId: process.env.NEXT_PUBLIC_APPLE_OAUTH_CLIENT_ID,
        RedirectUri: process.env.NEXT_PUBLIC_APPLE_OAUTH_REDIRECT,
    },
    DocumentServiceEndpoint: process.env.NEXT_PUBLIC_DOCUMENT_SERVICE_ENDPOINT?.replace(/\/$/, ""),
    GoogleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
    CloudinaryBaseUrl: "https://res.cloudinary.com/offshoot"
}

export const AppOrganisation: Organization = {
    "@type": "Organization",
    name: "Offshoot Rentals",
    url: "https://offshoot.rentals",
    address: {
        "@type": "PostalAddress",
        contactType: "Headquarters",
        streetAddress: "7 Kerr Street",
        addressLocality: "Preston",
        addressRegion: "Victoria",
        postalCode: "3072",
        addressCountry: "Australia"
    },
    email: "hello@offshoot.rentals",
    telephone: "+61 3 6121 9008",
    image: `${Config.CloudinaryBaseUrl}/q_50,w_1200,f_auto/Backgrounds/alexa_mini_lf_atlas_ana_odjmmt`
}

export default Config;