import {SvgIcon, SvgIconProps} from "@material-ui/core";
import GoogleLogoSvg from "../../vectors/GoogleLogo";

interface IGoogleLogoProps extends SvgIconProps{
    /**
     * Set to true to use Google branding colours.
     */
    useGoogleColours?: boolean;
}

export default function GoogleLogo({useGoogleColours, ...rest}: IGoogleLogoProps) {
    return (
        <SvgIcon {...rest}>
            <GoogleLogoSvg useGoogleColours={useGoogleColours} />
        </SvgIcon>
    )
}