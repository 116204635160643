import {IOauthCallbackState} from "../types";
import AppConfig from "../config";

/**
 * Returns the OAuth redirect URI for a supported OAuth IDP.
 *
 * @param provider
 */
export function getProviderRedirectUri(provider: IOauthCallbackState["provider"]) {
    switch(provider) {
        case "google":
            return `${window.location.origin}/accounts/oauth-callback`;
        case "apple":
            return AppConfig.AppleSignIn.RedirectUri;
        default:
            throw new Error("Unsupported IDP provider");
    }
}