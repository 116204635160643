import StandardButton from "../buttons/standardButton";
import AppleIcon from "../icons/apple";
import {ButtonProps, CircularProgress} from "@material-ui/core";
import {IOauthCallbackState} from "../../types";
import {useCallback, useState} from "react";
import {getProviderRedirectUri} from "../../utils/oauth";
import AppConfig from "../../config";
import {encodeBase64} from "../../utils/url";
import {IdentityProvider} from "../../__generated__/globalTypes";

interface IAppleSignInButtonProps extends Pick<ButtonProps, "size"|"disabled"> {
    label?: string;
    onClick?: () => void;
    state?: Omit<IOauthCallbackState,"provider">;
}

export default function AppleSignInButton({label, onClick, disabled, state, ...props}: IAppleSignInButtonProps) {
    const [redirecting, setRedirecting] = useState<boolean>(false);

    const handleClick = useCallback(() => {
        setRedirecting(true);
        onClick?.();
        const redirectUri = encodeURIComponent(getProviderRedirectUri(IdentityProvider.apple));
        const clientId = encodeURIComponent(AppConfig.AppleSignIn.ClientId);
        //
        // The state will be passed back to us in the callback. We will pass it as a
        // URL encoded base64 string and decode it in the callback.
        //
        const b64State = encodeBase64({
            ...state,
            provider: IdentityProvider.apple
        } as IOauthCallbackState);
        window.location.href = `https://appleid.apple.com/auth/authorize?response_type=code&response_mode=form_post&scope=name%20email&client_id=${clientId}&redirect_uri=${redirectUri}&state=${b64State}`;
    }, [state, onClick]);

    return (
        <StandardButton
            {...props}
            disabled={disabled}
            startIcon={redirecting
                ? <CircularProgress size={15} />
                : <AppleIcon />}
            onClick={handleClick}>
            {label ?? 'Sign In With Apple ID'}
        </StandardButton>
    )
}