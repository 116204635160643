
const Errors = {
    Unauthenticated: "UNAUTHENTICATED",
    AuthenticationFailure: "AUTHENTICATION_FAILURE",
    PermissionCheckFailed: "PERMISSION_CHECK_FAILED",
    ProductCategoryNotFound: "PRODUCT_CATEGORY_NOT_FOUND",
    UserAlreadyExists: "USER_ALREADY_EXISTS",
    AccountNameExists: "ACCOUNT_NAME_EXISTS",
    InsufficientStock: "INSUFFICIENT_STOCK",
    UserAccountDisabled: "USER_ACCOUNT_HAS_BEEN_DISABLED",
    IncorrectValidationCode: "INCORRECT_VALIDATION_CODE",
    RateLimited: "RATE_LIMITED"
}

export default Errors;
