import {createTheme, responsiveFontSizes} from '@material-ui/core/styles';
import {grey, red, pink, deepPurple} from "@material-ui/core/colors";
import {darken, decomposeColor, lighten, Theme} from "@material-ui/core";

export default function createReisTheme(darkMode: boolean): Theme {
    const bgDefault = darkMode ? "#303030" : "#fafafa";
    let theme = createTheme({
        typography: {
            fontFamily: "Poppins, sans-serif",
            fontWeightLight: 200,
            fontWeightMedium: 400,
            fontWeightBold: 600,
            h1: {
                fontWeight: 600,
                fontSize: "2.4rem",
            },
            h2: {
                fontSize: "2rem",
                fontWeight: 600
            },
            h3: {
                fontSize: "1.5rem",
                fontWeight: 600
            },
            h4: {
                fontSize: "1.4rem",
                fontWeight: 600
            },
            h5: {
                fontWeight: 600
            },
            h6: {
                fontWeight: 600
            }
        },
        components: {
            MuiCssBaseline: {
                styleOverrides: {
                    html: {
                        minHeight: "100%"
                    },
                    body: {
                        minHeight: "100vh",
                        border: 0,
                        padding: 0,
                        backgroundColor: bgDefault
                    },
                    a: {
                        color: "inherit",
                        textDecoration: "none"
                    },
                    "a.underlined": {
                        fontWeight: 400,
                        textUnderlineOffset: "2px",
                        textDecoration: "underline",
                        textDecorationStyle: "dotted",
                        textDecorationThickness: "1px",
                    },
                    "::selection": {
                        background: darkMode? pink.A700 : "#3d3c3c",
                        color: "white",
                        WebkitTextFillColor: "white",
                    },
                    "*::-webkit-scrollbar": {
                        width: "0.7em"
                    },
                    "*::-webkit-scrollbar-track": {
                        boxShadow: "none",
                    },
                    "*::-webkit-scrollbar-thumb": {
                        backgroundColor: darkMode ? lighten("#303030",0.15) : "#444444",
                        outline: "none"
                    }
                }
            },
            MuiFilledInput: {
                styleOverrides: {
                    root: {
                        backgroundColor: darkMode ? "rgba(100,100,100,0.15)" : "rgba(0,0,0,0.05)"
                    }
                }
            },
            MuiLink: {
                styleOverrides: {
                    root: {
                        color: "inherit",
                        cursor: "pointer",
                        textDecoration: "none"
                    }
                }
            },
            MuiDialogActions: {
                styleOverrides: {
                    root: {
                        padding: "12px"
                    }
                }
            },
            MuiPaper: {
                styleOverrides: {
                    root: {
                        backgroundImage: "unset"
                    }
                }
            },
            MuiTab: {
                defaultProps: {
                    disableRipple: true
                },
                styleOverrides: {
                    root: {
                        fontWeight: 600
                    }
                }
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        fontWeight: 600
                    }
                },
                defaultProps: {
                    disableRipple: true
                }
            }
        },
        palette: {
            mode: darkMode ? "dark" : "light",
            primary: {
                main: darkMode ? "#eaf2bc" : grey["500"]
            },
            secondary: {
                main: darkMode ? "#eaf2bc" : grey["800"]
            },
            background: {
                default: darkMode ? "#303030" : "#fafafa",
                paper: darkMode ? "#424242" : "#ffffff"
            },
            text: {
                hint: 'rgba(0, 0, 0, 0.38)'
            },
            custom: {
                footer: darkMode ? darken("#424242", 0.4) : darken(grey["50"], 0.06),
                backdrop: "rgba(0,0,0,0.4)",
                highlight: darkMode ? pink.A700 : grey["800"],
                border: darkMode ? grey["700"] : grey["500"],
                alert: darkMode ? `rgba(${decomposeColor("#eaf2bc").values.join(',')}, 0.2)` : "#99d893",
                notices: darkMode ? "#8c5506" : "#77cc70"
            },
            error: {
                main: darkMode ? pink.A100 : red["600"]
            }
        }
    });
    return responsiveFontSizes(theme, {factor: 1.6});
}

export function shortfallBackgroundColour(theme: Theme) {
    return theme.palette.mode === "dark"
        ? `rgba(${decomposeColor(theme.palette.secondary.dark).values.join(',')},0.2)`
        : lighten(theme.palette.info.light, 0.5);
}