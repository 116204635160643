import {SvgIcon, SvgIconProps} from "@material-ui/core";

export default function AppleIcon(props: SvgIconProps) {
    return(
        <SvgIcon viewBox="0 0 256 314.4" {...props}>
            <path
                d="M213.8,167c.45,47.58,41.74,63.41,42.2,63.61a172,172,0,0,1-21.76,44.72c-13.1,19.15-26.7,38.24-48.13,38.63-21.05.39-27.82-12.48-51.89-12.48s-31.58,12.09-51.5,12.87c-20.68.78-36.43-20.71-49.65-39.79-27-39-47.63-110.3-19.92-158.41,13.76-23.89,38.36-39,65.05-39.4,20.31-.39,39.48,13.66,51.89,13.66s35.7-16.9,60.19-14.42c10.25.43,39,4.14,57.5,31.19-1.49.92-34.33,20-34,59.82M174.24,50.2c11-13.29,18.37-31.79,16.35-50.2-15.82.64-35,10.55-46.31,23.83C134.11,35.59,125.2,54.42,127.6,72.46c17.64,1.37,35.66-9,46.64-22.26"
                transform="translate(0)"/>
        </SvgIcon>
    )
}