import React, {useCallback, useMemo, useState} from "react";


interface ISearchContext {
    openSearch: (open: boolean) => void;
    searchIsOpen: boolean;
}

export const SearchContext = React.createContext<ISearchContext>({
    openSearch: () => null,
    searchIsOpen: false
});

const SearchContextProvider: React.FC = ({children}) => {
    const [searchIsOpen, setSearchIsOpen] = useState<boolean>(false);

    const openSearch = useCallback((open: boolean) => {
        setSearchIsOpen(open);
    }, []);

    const context = useMemo<ISearchContext>(() => {
        return {
            searchIsOpen,
            openSearch
        }
    }, [searchIsOpen, openSearch]);

    return (
        <SearchContext.Provider value={context}>
            {children}
        </SearchContext.Provider>
    )
};

export default SearchContextProvider;