import { InMemoryCache, makeVar } from "@apollo/client";
import {IApplicationUser} from "./types";

export const applicationUser = makeVar<IApplicationUser|null>(null);
export const colourModePreference = makeVar<string>('');

export const cache: InMemoryCache = new InMemoryCache({
    // typePolicies: {
    //     Query: {
    //         fields: {
    //             currentApplicationUser: {
    //                 read () {
    //                     return applicationUser();
    //                 }
    //             }
    //         }
    //     }
    // }
});
