import {UrlObject} from "url";
import NextLink, {LinkProps} from "next/link";
import {Link} from "@material-ui/core";

interface IStandardLinkProps {
    href: string | UrlObject;
    prefetch?: LinkProps["prefetch"];
    onClick?: () => void;
}

const StandardLink: React.FC<IStandardLinkProps> = ({href, prefetch=false, onClick, children}) => {
    return (
        <NextLink href={href} passHref={true} prefetch={prefetch}>
            <Link className={"underlined"} onClick={onClick}>
                {children}
            </Link>
        </NextLink>
    )
}

export default StandardLink;