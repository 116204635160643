
export const ApplicationName = "customer_app";

export const Topics = {
    Payments: "payments",
    Users: "users",
    Customers: "customers",
    Jobs: "jobs",
    Locations: "locations",
    ClientEvent: "client_event"
}

export const Roles = {
    Staff: "staff",
    Management: "management"
}

export const StorageKeys = {
    QuotingProducts: "quoting_products",
    ActiveJob: "active_job",
    ActiveCustomer: "active_customer",
    ColourMode: "colour_mode",
    CategoryFilterPrefix: "category_filters_",
    NoUserActionTracking: "user_action_reporting",
    NoticesDismissed: "notice_acks"
}

export const QueryKeys = {
    NoCredentials: "nocred"
}

export const ApplicationEvents = {
    SignOut: "sign_out"
}

export enum AuthenticationFailureReason {
    /**
     * The email_verified property of an OIDC token is set to false.
     */
    EmailAddressNotVerifiedByIdentityProvider="EMAIL_ADDRESS_NOT_VERIFIED_BY_IDP",
    /**
     * The user successfully authenticated with the IDP but we have no user
     * account matching the email address in the OIDC ID token.
     */
    NoUserMatchingIdpEmailAddress="NO_USER_MATCHING_IDP_EMAIL_ADDRESS",
    /**
     * The user's account has been disabled.
     */
    AccountHasBeenDisabled="ACCOUNT_HAS_BEEN_DISABLED",
}

export enum CustomerAccountType {
    Regular="REG",
    Pro="PRO"
}

export const CannotConfirmJobReason = {
    PersonLinkedToJobIsNotVerified: "PERSON_LINKED_TO_JOB_IS_NOT_VERIFIED",
    PersonLinkedToJobHasNoMobile: "PERSON_LINKED_TO_JOB_HAS_NO_MOBILE",
    AccountOfLinkedPersonHasBeenDisabled: "ACCOUNT_OF_LINKED_PERSON_HAS_BEEN_DISABLED",
    CustomerDoesNotHaveProAccount: "CUSTOMER_DOES_NOT_HAVE_PRO_ACCOUNT",
    InsufficientStock: "INSUFFICIENT_STOCK",
    AfterConfirmationCutoff: "AFTER_CONFIRMATION_CUTOFF",
    AfterFirstDayOfJob: "AFTER_FIRST_DAY_OF_JOB",
}
